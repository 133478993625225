import React, { Component } from "react";

class Github extends Component {
  render() {
    return (
      <a
        href="https://github.com/dane-brown"
        className="footer-icon"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          width="50"
          height="51"
          viewBox="0 0 50 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.9969 0.0268555C11.1938 0.0268555 0 11.5454 0 25.7546C0 37.1194 7.1625 46.7609 17.1 50.1658C18.35 50.4029 18.8062 49.6085 18.8062 48.9262C18.8062 48.3144 18.7844 46.6968 18.7719 44.5507C11.8188 46.1042 10.35 41.1009 10.35 41.1009C9.21562 38.1284 7.575 37.3372 7.575 37.3372C5.30312 35.742 7.74375 35.7741 7.74375 35.7741C10.2531 35.9566 11.5719 38.4263 11.5719 38.4263C13.8031 42.3565 17.425 41.2226 18.85 40.5628C19.075 38.9003 19.7219 37.7664 20.4375 37.1226C14.8875 36.4723 9.05 34.2654 9.05 24.4093C9.05 21.6001 10.025 19.3034 11.625 17.5033C11.3656 16.853 10.5094 14.236 11.8688 10.6966C11.8688 10.6966 13.9688 10.0047 18.7437 13.3328C20.7375 12.7626 22.875 12.4775 25.0031 12.4679C27.125 12.4807 29.2656 12.7626 31.2625 13.336C36.0344 10.0079 38.1312 10.6998 38.1312 10.6998C39.4937 14.2425 38.6375 16.8562 38.3812 17.5065C39.9844 19.3066 40.95 21.6033 40.95 24.4125C40.95 34.2942 35.1062 36.4691 29.5375 37.1066C30.4344 37.901 31.2344 39.4705 31.2344 41.8697C31.2344 45.3098 31.2031 48.0838 31.2031 48.9262C31.2031 49.6149 31.6531 50.4157 32.9219 50.1626C42.8438 46.7545 50 37.1162 50 25.7546C50 11.5454 38.8063 0.0268555 24.9969 0.0268555Z"
            fill="#6CC644"
          />
        </svg>
      </a>
    );
  }
}

export default Github;
