import React, { Component } from "react";

class Twitter extends Component {
  render() {
    return (
      <a
        className="footer-icon"
        href="https://twitter.com/dane_brown_47"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          width="50"
          height="52"
          viewBox="0 0 50 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50 25.6257C50 39.7784 38.8071 51.2515 25 51.2515C11.1929 51.2515 0 39.7784 0 25.6257C0 11.473 11.1929 0 25 0C38.8071 0 50 11.473 50 25.6257ZM36.881 19.3806C38.0896 19.2335 39.2421 18.9041 40.3134 18.4172C39.5126 19.6455 38.4996 20.724 37.3311 21.5873C37.3427 21.8495 37.3485 22.114 37.3485 22.3794C37.3485 30.4741 31.3376 39.8071 20.3461 39.8071C16.9717 39.8071 13.8298 38.7935 11.1867 37.055C11.6533 37.1121 12.1301 37.1409 12.6114 37.1409C15.4119 37.1409 17.9882 36.162 20.0337 34.5189C17.4182 34.4691 15.2118 32.6982 14.4515 30.2653C14.8161 30.3366 15.19 30.3745 15.5759 30.3745C16.12 30.3745 16.649 30.3 17.1499 30.1593C14.4168 29.597 12.3561 27.1217 12.3561 24.154C12.3561 24.1284 12.3561 24.1024 12.3565 24.0763C13.1618 24.5363 14.0839 24.8117 15.063 24.8433C13.4604 23.7452 12.4047 21.8696 12.4047 19.7451C12.4047 18.6227 12.6997 17.5699 13.214 16.6654C16.1615 20.3722 20.5649 22.8106 25.5312 23.0659C25.4287 22.6173 25.3757 22.1501 25.3757 21.67C25.3757 18.2879 28.0518 15.5449 31.3514 15.5449C33.0712 15.5449 34.6243 16.2886 35.7143 17.4785C37.0749 17.204 38.3543 16.6947 39.509 15.9926C39.062 17.4237 38.115 18.6227 36.881 19.3806Z"
            fill="#1DA1F2"
          />
        </svg>
      </a>
    );
  }
}

export default Twitter;
